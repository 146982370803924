import React from 'react'

type IIconProps = {
  color?: string
}

const TwitterIcon: React.FC<React.SVGProps<SVGSVGElement> & IIconProps> = ({
  color = 'currentColor',
  ...rest
}) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18.68 15.39" {...rest}>
      <path
        fill={color}
        d="M6.19,15.39A10.93,10.93,0,0,0,16.78,4.33c0-.16,0-.33,0-.5a7.62,7.62,0,0,0,1.91-2,7.49,7.49,0,0,1-2.2.61A3.85,3.85,0,0,0,18.16.28a7.65,7.65,0,0,1-2.43,1A3.8,3.8,0,0,0,12.93,0,3.86,3.86,0,0,0,9.1,3.89a3.65,3.65,0,0,0,.1.88A10.82,10.82,0,0,1,1.3.71,3.93,3.93,0,0,0,2.48,5.9,3.74,3.74,0,0,1,.75,5.41v0A3.88,3.88,0,0,0,3.82,9.27a3.73,3.73,0,0,1-1,.14,4.18,4.18,0,0,1-.72-.07A3.84,3.84,0,0,0,5.67,12,7.57,7.57,0,0,1,.91,13.7,6.84,6.84,0,0,1,0,13.64H0a10.84,10.84,0,0,0,5.65,1.74h.54"
      />
    </svg>
  )
}

export default TwitterIcon
